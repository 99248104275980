@media (max-width: 1199px) {
  .menuAdmin {
    display: none !important;
  }
  .show {
    display: block !important;
  }
}
@media (max-width: 991px) {
  .menu-nav {
    display: block !important;
  }
}
@media (min-width: 200px) and (max-width: 991px) {
  .collapse1 {
    display: flex;
    flex-direction: row;
  }
  .item-hidden {
    display: none !important;
  }
  .header-nav {
    display: flex !important;
    flex-direction: row;
  }
}
.collapse1 {
  display: flex;
}
/* Add this CSS in the same directory as ManageRoom.jsx and import it in the component file */

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.pagination-btn {
  background-color: #f0f0f0;
  color: black;
  border: none;
  padding: 0.5rem 0.75rem;
  margin: 0 0.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-btn:hover {
  background-color: #d0d0d0;
}

.pagination-btn.active {
  background-color: #007bff;
  color: white;
}

.table-responsive {
  overflow-x: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.25rem;
  width: 90%;
  max-width: 500px;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
